<template>
  <div class="home-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search"></search-form>
    <!-- 任务详情列表 -->
    <task-detail :datas="searchVal"></task-detail>
    <!-- 任务详情列表 -->
    <detail-list :datas="searchVal"></detail-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TaskDetail from './component/taskDetail.vue'
import DetailList from './component/DetailList.vue'
export default {
  components: {
    SearchForm,
    DetailList,
    TaskDetail
  },
  data() {
    return {
      // 表单
      form: {
        datetimerange: [], // 时间段
        taskID: ''
      },
      searchVal: {
        datetimerange: [], // 时间段
        taskID: ''
      }
    }
  },
  created() {
    if (this.$route.query.taskID) {
      this.form.taskID = this.$route.query.taskID
      this.searchVal.taskID = this.$route.query.taskID
      console.log('taskID:', this.searchVal.taskID)
    }
  },
  mounted() {},
  methods: {
    // 搜索
    search(form) {
      console.log('搜索：', form)
      // 按条件搜索
      this.searchVal = form
    }
  }
}
</script>
