<template>
  <!-- 账本信息 -->
  <div class="detail-info"
       v-if="taskData">

    <div class="header">
      <span>合约任务详情</span>
    </div>
    <div class="content">
      <el-row>
        <el-col class="div-column details-div"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                :xl="12">
          <div class="div-row details-list">
            <div class="label-title">分片名</div>
            <div class="details-content">{{taskData.shard}}</div>
          </div>
          <div class="div-row details-list">
            <div class="label-title">合约ID</div>
            <el-popover v-if="taskData.contractID"
                        width="500"
                        placement="top-start"
                        trigger="hover"
                        @show="getDictValue(taskData.shardName, taskData.contractID)">
            <div class="div-column">
              <div class="dict-value">原文：</div>
              <div style="margin-bottom:10px">{{taskData.contractID}}</div>
              <div class="dict-value">译文：</div>
              <div v-if="dictValue.length !== 0">
                <div v-for="(it,index) in dictValue"
                     :key="index">
                  {{it.dictValue}}
                </div>
              </div>
              <div v-else>暂无译文</div>
            </div>
              <div class="details-content" style="margin-top: 12px;"
                   slot="reference">{{ taskData.contractID }}</div>
            </el-popover>
            <i v-if="taskData.contractID"
               class="el-icon-document-copy"
               @click="copy($event, taskData.contractID)"></i>
            <div v-else
                 class="details-content">无</div>
          </div>
          <div class="div-row details-list">
            <div class="label-title">函数名</div>
            <div class="details-content">{{taskData.func}}</div>
          </div>
          <div class="div-row details-list">
            <div class="label-title">外部参数</div>

            <el-popover v-if="taskData.args"
                        width="500"
                        placement="top-start"
                        trigger="hover"
                        :content="taskData.args">
              <div class="details-content shenglue"
                   slot="reference">{{ taskData.args }}</div>
            </el-popover>
            <div v-else
                 class="details-content">无</div>
          </div>

          <div class="div-row details-list">
            <div class="label-title">开始时间</div>
            <div class="details-content">{{tf(taskData.startTime, 'YYYY-MM-DD HH:mm:ss')}}</div>
          </div>

          <div class="div-row details-list">
            <div class="label-title">执行间隔/(秒)</div>
            <div class="details-content">{{taskData.cron}}</div>
          </div>

          <div class="div-row details-list">
            <div class="label-title">执行次数/(次)</div>
            <div class="details-content">{{taskData.cycle}}</div>
          </div>
        </el-col>
        <el-col class="div-column details-div"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                :xl="12">
          <div class="div-row details-list">
            <div class="label-title">执行条件</div>
            <div class="details-content">{{getTriggerTypeName(taskData.startTriggerID)}}</div>
          </div>

          <div v-if="taskData.start !== []">
            <div class="div-row details-list"
                 v-for="(item, index) in taskData.start"
                 :key="index">
              <div class="label-title">{{getStartName(item.triggerID)}}</div>
              <el-popover v-if="item.triggerValue.length > 38"
                          width="500"
                          placement="top-start"
                          trigger="hover"
                          :content="item.triggerValue">
                <div class="details-content shenglue"
                     slot="reference">{{ item.triggerValue }}</div>
              </el-popover>
              <div v-else
                   class="details-content">{{item.triggerValue}}</div>
            </div>
          </div>

          <div class="div-row details-list">
            <div class="label-title">停止条件</div>
            <div class="details-content">{{getTriggerTypeName(taskData.stopTriggerID)}}</div>
          </div>

          <div v-if="taskData.end !== []">
            <div class="div-row details-list"
                 v-for="(item, index) in taskData.end"
                 :key="index">
              <div class="label-title">{{getStopName(item.triggerID)}}</div>
              <el-popover v-if="item.triggerValue.length > 38"
                          width="500"
                          placement="top-start"
                          trigger="hover"
                          :content="item.triggerValue">
                <div class="details-content shenglue"
                     slot="reference">{{ item.triggerValue }}</div>
              </el-popover>
              <div v-else
                   class="details-content">{{item.triggerValue}}</div>
            </div>
          </div>

        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import timeFormat from '@/utils/timeFormat.js'
import interceptStr from '@/utils/interceptStr.js'
export default {
  name: 'taskDetails',
  data() {
    return {
      taskData: '',
      dictValue: [], // 译文
      triggerList: [], // 父类条件数组
      startList: [], // 执行条件数组
      stopList: [] // 停止条件数组
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        console.log('监听:', this.datas)
        // 清空数据
        this.taskData = ''
        // 查询合约任务信息
        this.getData()
      },
      deep: false,
      immediate: true
    }
  },
  created() {
    this.getTriggerType()
  },
  mounted() {
    if (this.$route.query.taskID) {
      this.getData(this.$route.query.taskID)
    }
  },
  methods: {
    // 译文查询
    getDictValue(shardName, key) {
      // 进行匹配
      let body = {
        shardName: shardName,
        dictKey: key
      }
      let param = {
        page: 0,
        size: 1
      }
      this.$api.dictionary.shardData(param, body).then((res) => {
        if (res.code === 200) {
          // console.log(res.data.content)
          this.dictValue = res.data.content
        }
      })
    },
    // 判断条件类型
    getTriggerTypeName(triggerID) {
      if (this.triggerList !== []) {
        for (let item of this.triggerList) {
          if (triggerID === item.value) {
            return item.label
          }
        }
      }
    },
    // 获取执行条件参数名称
    getStartName(triggerID) {
      if (this.startList !== []) {
        for (let item of this.startList) {
          if (triggerID === item.id) {
            return item.label
          }
        }
      }
    },
    // 获取停止条件参数名称
    getStopName(triggerID) {
      if (this.stopList !== []) {
        for (let item of this.stopList) {
          if (triggerID === item.id) {
            return item.label
          }
        }
      }
    },
    // 获取分类触发条件
    async getTriggerType() {
      const { data } = await this.$api.middleWare.triggerType()
      // 进行转化
      var array = []
      for (let item of data) {
        var obj = {}
        obj.value = item.id
        obj.label = item.label
        array.push(obj)
      }
      this.triggerList = array
    },
    async getTriggerArgs(id, type) {
      let options = {
        triggerID: id
      }
      const { data } = await this.$api.middleWare.triggerArgs(options)
      if (type === 1) {
        this.startList = data
      } else if (type === 2) {
        this.stopList = data
      }
    },
    // 查询合约任务信息
    async getData() {
      let options = {
        taskID: this.$route.query.taskID
      }
      const { data } = await this.$api.middleWare.taskDetails(options)
      // console.log(data)
      if (data) {
        this.taskData = data
        // 获取执行条件参数
        this.getTriggerArgs(data.startTriggerID, 1)
        // 获取停止条件参数
        this.getTriggerArgs(data.stopTriggerID, 2)
      }
    },
    // 字段截取
    behindStr(value) {
      return interceptStr.behindStr(value, 32)
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 一键复制
    copy(e, text) {
      if (this.copyLoading) {
        return
      }
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
      setTimeout(() => {
        this.copyLoading = false
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detail-info {
  background: #fff;
  margin-bottom: 12px;
  // border: 1px solid #cecece;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 16px;
    border-bottom: 1px solid #cecece;
    background: #fff;
  }
  .content {
    background: #fff;
    margin-bottom: 12px;
    .div-column {
      display: flex;
      flex-direction: column;
    }
    .div-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .btn-left {
      margin-left: 16px;
    }
    .details-div {
      padding: 20px 0;
    }
    .details-list {
      line-height: 50px;
      color: #757575;
      // padding-left: 32px;
      .label-title {
        padding-right: 60px;
        width: 200px;
        text-align: right;
        font-weight: bold;
      }
      .details-content {
        color: #3f536e;
        width: 360px;
        word-wrap: break-word;
        line-height: 20px;
      }
    }
    .data-list {
      display: flex;
      flex-direction: column;
      align-taskdatas: center;
      &.nothing {
        padding-bottom: 20px;
        margin: 20px auto;
        font-size: 14px;
        color: #222b45;
        img {
          width: 104px;
          height: 105px;
        }
        .nothing-text {
          margin-left: -11px;
        }
      }
    }
  }
  // 不换行省略
  .shenglue {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  // js样式
  /deep/ pre {
    min-height: 400px;
    background: rgb(241, 241, 241);
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    overflow-x: scroll;
    .string {
      color: green;
    }
    .number {
      color: darkorange;
    }
    .boolean {
      color: blue;
    }
    .null {
      color: magenta;
    }
    .key {
      color: red;
    }
  }
  /deep/ pre::-webkit-scrollbar {
    height: 10px;
  }
}
</style>
