<template>
  <div>
    <table-list :columns="columns"
                :data="list"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">

     <!-- 执行时间 -->
     <template #createTime="{row}">
        {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
     </template>

     <!-- 执行状态 -->
     <!-- <template #status="{row}">
        <span v-if="row.status === 0">未执行</span>
        <span v-else-if="row.status === 1">执行中</span>
        <span v-else-if="row.status === 2">已结束</span>
      </template> -->

      <!-- 执行结果 -->
     <template #remark="{row}">
       <span class="table-row shenglue-1" v-if="row.remark"  @click="handleOpen(row.remark)">
         {{ row.remark }}
       </span>
       <span v-else>无</span>
      </template>

    </table-list>
    <!-- Dialog -->
    <el-dialog :title="title"
               width="55%"
               :visible.sync="dialogVisible">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from './tableData.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        console.log('监听:', this.datas)
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList
  },
  data() {
    return {
      dialogVisible: false, // 弹窗
      loading: false,
      title: '',
      info: '',
      list: [], // 列表
      body: {},
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: tableObj.tableData,
      headData: tableObj.headData
    }
  },
  created() {},
  mounted() {

  },
  methods: {
    handleOpen(value) {
      this.title = '执行结果'
      // console.log(value)
      this.info = value
      // this.info = this.syntaxHighlight(JSON.parse(value))
      this.dialogVisible = true
    },
    syntaxHighlight(_json) {
      var json = {}
      if (typeof _json !== 'string') {
        for (let item in _json) {
          var value = _json[item]
          if (value == null || value === undefined) {
            continue
          }
          json[item] = value
        }
        json = JSON.stringify(json, undefined, 2)
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
      return json.replace(
        // eslint-disable-next-line no-useless-escape
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    dataBody() {
      var datas = this.datas
      if (datas.datetimerange.length !== 0) {
        var start = new Date(datas.datetimerange[0]).getTime()
        var end = new Date(datas.datetimerange[1]).getTime()
        let body = {
          start: start,
          end: end
        }
        console.log({ start: start })
        console.log({ end: end })
        return body
      } else {
        let body = {
          start: null,
          end: null
        }
        return body
      }
    },
    async getData(currentPage) {
      let option = {
        page: currentPage - 1,
        size: this.pageData.size,
        taskID: JSON.parse(JSON.stringify(this.datas)).taskID
      }
      let { data, code } = await this.$api.middleWare.taskResult(option, this.dataBody())
      if (code === 200) {
        // console.log('数据1:', data.content)
        this.pageData.total = data.total
        this.list = data.content
      }
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row {
  color: $--color-primary;
}
.table-row:hover {
  text-decoration: underline;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// js样式
/deep/ pre {
  min-height: 400px;
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
/deep/ pre::-webkit-scrollbar {
  height: 10px;
}
</style>
